import { ReqMethod, fetchHandler } from '../index';
import { User } from '../users';

export enum LessonBlockType {
  LearningMaterial = 'learning-material',
  Video = 'video',
  Test = 'test',
  HomeWork = 'homework',
}
export type LessonBlock = {
  _id: string;
  name: string;
  content: string;
  type: LessonBlockType;
};
export type Lesson = {
  _id: string;
  name: string;
  grade?: number;
  blockId?: string;
  lessonBlocks: LessonBlock[] | null;
};

export type CourseBlock = {
  _id: string;
  name: string;
  courseLessons: Lesson[];
};

export type Course = {
  _id: string;
  name: string;
  description: string;
  features: string[];
  image: string;
  price: number;
  courseBlocks: CourseBlock[];
  courseResources?: string[];
};
type CreateCourseValues = {
  name: string;
  description: string;
  features: string[];
  image: string;
  price: number;
};
type CreateLessonBlockValues = {
  type: LessonBlockType;
  name: string;
  content: string;
};
type CreateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  values: CreateLessonBlockValues;
};
type UpdateLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
  content: string;
};
type RemoveLessonBlockParams = {
  courseId: string;
  blockId: string;
  lessonId: string;
  lessonBlockId: string;
};
export type Resource = {
  _id: string;
  title: string;
  text: string;
};
export enum HomeWorkStatus {
  Aproved = 'approved',
  Rejected = 'rejected',
  Submitted = 'submitted',
  Calovyi = 'calovyi',
}
export type HomeWork = {
  _id: string;
  studyFlow: string;
  user: User;
  filePaths: string[];
  mentor: User | null;
  createdAt: string;
  updatedAt: string;
  status: HomeWorkStatus;
  courseLesson: Lesson;
  userComment?: string;
  mentorComment?: string;
};

export type Grade = {
  grade: number;
  lessonBlock: string;
  user: string;
  _id: string;
};

export const createCourse = async (values: CreateCourseValues) => {
  await fetchHandler(`/courses`, {
    method: ReqMethod.POST,
    body: values,
  });
};

export const getCourses = async (): Promise<Course[]> =>
  fetchHandler(`/courses`, {
    method: ReqMethod.GET,
  });

export const getCourse = async (
  id: string,
  studyFlowId: string,
): Promise<Course> =>
  fetchHandler(`/courses/${id}/study-flow/${studyFlowId}`, {
    method: ReqMethod.GET,
  });

export const getCourseResources = async (id: string): Promise<Resource[]> =>
  fetchHandler(`/study-flows/${id}/resources`, {
    method: ReqMethod.GET,
  });

export const getCourseResource = async (
  id: string,
  resourceId: string,
): Promise<Resource> =>
  fetchHandler(`/study-flows/${id}/resources/${resourceId}`, {
    method: ReqMethod.GET,
  });

export const createCourseBlock = async (id: string, name: string) => {
  await fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.POST,
    body: { name },
  });
};

export const removeCourseBlock = async (
  courseId: string,
  courseBlockId: string,
) => {
  await fetchHandler(`/courses/${courseId}/course-blocks/${courseBlockId}`, {
    method: ReqMethod.DELETE,
  });
};

export const getCourseBlocks = async (id: string): Promise<CourseBlock[]> =>
  fetchHandler(`/courses/${id}/course-blocks`, {
    method: ReqMethod.GET,
  });

export const createCourseLesson = async (
  id: string,
  blockId: string,
  name: string,
): Promise<Lesson> =>
  fetchHandler(`/courses/${id}/course-blocks/${blockId}/course-lessons`, {
    method: ReqMethod.POST,
    body: { name },
  });

export const removeCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
) => {
  await fetchHandler(
    `/courses/${id}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.DELETE,
    },
  );
};
export const getCourseLesson = async (
  id: string,
  blockId: string,
  lessonId: string,
  studyFlowId: string,
): Promise<Lesson> =>
  await fetchHandler(
    `/courses/${id}/study-flow/${studyFlowId}/course-blocks/${blockId}/course-lessons/${lessonId}`,
    {
      method: ReqMethod.GET,
    },
  );

export const createLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  values,
}: CreateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks`,
    {
      method: ReqMethod.POST,
      body: values,
    },
  );

export const updateLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
  content,
}: UpdateLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.PUT,
      body: { content },
    },
  );

export const removeLessonBlock = async ({
  courseId,
  blockId,
  lessonId,
  lessonBlockId,
}: RemoveLessonBlockParams): Promise<Lesson> =>
  fetchHandler(
    `/courses/${courseId}/course-blocks/${blockId}/course-lessons/${lessonId}/lesson-blocks/${lessonBlockId}`,
    {
      method: ReqMethod.DELETE,
    },
  );

export const getUserHomeworks = async (
  lessonId: string,
  studyFlowId: string,
): Promise<HomeWork[]> =>
  fetchHandler(
    `/course-lessons/${lessonId}/study-flow/${studyFlowId}/user-homeworks`,
    {
      method: ReqMethod.GET,
    },
  );

export const submitHomework = async (
  lessonId: string,
  studyFlowId: string,
  body: any,
): Promise<void> =>
  fetchHandler(
    `/course-lessons/${lessonId}/study-flow/${studyFlowId}/user-homeworks`,
    {
      method: ReqMethod.POST,
      body,
    },
  );
export const updateHomework = async (
  lessonId: string,
  homeWorkId: string,
  body: any,
): Promise<void> =>
  fetchHandler(`/course-lessons/${lessonId}/user-homeworks-u/${homeWorkId}`, {
    method: ReqMethod.PUT,
    body,
  });

export const finishHomework = async (
  lessonId: string,
  studyFlowId: string,
  body: any,
): Promise<void> =>
  fetchHandler(`/course-lessons/${lessonId}/study-flow/${studyFlowId}/finish`, {
    method: ReqMethod.POST,
    body,
  });

export const getLessonGrade = async (
  lessonBlockId: string,
  userId: string,
): Promise<Grade> =>
  fetchHandler(`/grades/lesson/${lessonBlockId}/users/${userId}`, {
    method: ReqMethod.GET,
  });
