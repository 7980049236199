import {
  Box,
  Button,
  FileButton,
  Flex,
  Image,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import {
  HomeWork as HomeWorkType,
  HomeWorkStatus,
  Lesson,
  LessonBlock,
  submitHomework,
  getLessonGrade,
  updateHomework,
} from '../../../../api/courses';
import { FC, useState } from 'react';
import dayjs from 'dayjs';
import RichTextContent from '../../../../components/RichTextContent';
import Textarea from '../../../../components/Inputs/Textarea';
import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/AttachmentIcon.svg';
import classes from '../Lesson.module.css';
import { bodyTextColorHex } from '../../../../theme';
import { fetchFile } from '../../../../api';
import { useQuery } from '@tanstack/react-query';
import useUser from '../../../../hooks/useUser';
import EmptyUser from '../../../../assets/images/EmptyUser.jpeg';
import { useParams } from 'react-router-dom';

type Props = {
  lesson: Lesson;
  lessonBlock: LessonBlock;
  homeworks?: HomeWorkType[];
  refetchHomeworks: () => Promise<any>;
  refetchCourse: () => Promise<any>;
};

export const FIVE_MB = 5242880;
const MAX_HOMEWORK_FILES = 20;

const formatDate = (date: string) =>
  dayjs(new Date(date)).format('DD.MM.YYYY | h:mm');

const HomeWork: FC<Props> = ({
  lesson,
  lessonBlock,
  homeworks,
  refetchHomeworks,
  refetchCourse,
}) => {
  const { studyFlowId } = useParams();
  const { user } = useUser();

  const { data: fetchedGrade } = useQuery({
    queryKey: ['grade', lessonBlock._id, user._id],
    queryFn: async () =>
      lessonBlock._id && user._id
        ? getLessonGrade(lessonBlock._id, user._id)
        : undefined,
  });

  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [files, setFiles] = useState<File[] | null>(null);

  const lastHomework = homeworks?.length
    ? homeworks[homeworks?.length - 1]
    : null;
  const handleUpdateHomework = async () => {
    setError('');
    try {
      setIsLoading(true);
      const changeHomework = async (): Promise<any> => {
        let formData = new FormData();
        if (files?.length) {
          files.forEach((file) => {
            formData.append('files', file);
          });
        }

        if (value) {
          formData.append('userComment', value);
        }
        if (lastHomework?.status == HomeWorkStatus.Submitted) {
          await updateHomework(lesson._id, lastHomework._id, formData);
          return;
        }
        await submitHomework(lesson._id, studyFlowId as string, formData);
      };

      await changeHomework();

      await refetchHomeworks();
      await refetchCourse();
      setIsLoading(false);
      setValue('');
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  const handleFileDownload = async (filePath: string, name: string) => {
    const blob = await fetchFile('/files-manager/download-file', filePath);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };
  return (
    <Flex
      className={classes.lessonContent}
      direction="column"
      justify="space-between"
    >
      <Flex direction="column" mb="50px">
        <Title order={4} mb="24px" fz="24px" fw="700">
          {lesson.name}
        </Title>
        <RichTextContent content={lessonBlock.content} />
      </Flex>
      {homeworks?.length ? (
        <Box h="100%">
          {homeworks.map((homeWork) => {
            if (homeWork.status === HomeWorkStatus.Submitted) {
              return (
                <Flex className={classes.commentWrap}>
                  <Avatar avatar={user.avatar} />

                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {!!homeWork?.filePaths?.length && (
                    <Flex wrap="wrap">
                      {homeWork?.filePaths.map((filePath) => {
                        const fileName = filePath?.split('/')[2];
                        return (
                          <Box m="4px">
                            <File
                              name={fileName}
                              onClick={async () =>
                                handleFileDownload(filePath, fileName)
                              }
                            />
                          </Box>
                        );
                      })}
                    </Flex>
                  )}
                </Flex>
              );
            }
            return (
              <>
                <Flex className={classes.commentWrap}>
                  <Avatar avatar={user.avatar} />
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      {homeWork.user.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.createdAt)}
                    </Box>
                  </Flex>
                  <Box className={classes.comment}>{homeWork.userComment}</Box>
                  {!!homeWork?.filePaths?.length && (
                    <Flex wrap="wrap">
                      {homeWork?.filePaths.map((filePath) => {
                        const fileName = filePath?.split('/')[2];
                        return (
                          <Box m="2px">
                            <File
                              name={fileName}
                              onClick={async () =>
                                handleFileDownload(filePath, fileName)
                              }
                            />
                          </Box>
                        );
                      })}
                    </Flex>
                  )}
                </Flex>
                <Flex className={classes.commentWrap}>
                  <Avatar avatar={homeWork.mentor?.avatar} />
                  <Flex mb="5px">
                    <Box className={classes.commentAuthor} mr="20px">
                      Ментор {homeWork.mentor?.firstName}
                    </Box>
                    <Box className={classes.commentDate}>
                      {formatDate(homeWork.updatedAt)}
                    </Box>
                  </Flex>

                  <Box className={classes.comment}>
                    {homeWork.mentorComment}
                  </Box>
                </Flex>
              </>
            );
          })}
        </Box>
      ) : null}
      {lastHomework?.status === HomeWorkStatus.Aproved ? (
        <Flex justify="space-between" align="center">
          <Box>
            <Text c="green">
              Домашнє завдання успішно виконане <br />
            </Text>
            {fetchedGrade?.grade && (
              <Text mt="md" fz="lg">
                Оцінка: <b>{fetchedGrade?.grade}</b>
              </Text>
            )}
          </Box>
        </Flex>
      ) : (
        <Box>
          <Flex
            justify="space-between"
            className={classes.homeWorkInputContainer}
            align="center"
          >
            <Textarea
              variant="black"
              size="md"
              onChange={(e) => setValue(e?.target?.value ?? '')}
              value={value}
              placeholder="Введіть коментар"
              className={classes.textarea}
              classNames={{ input: classes.textareaInput }}
            />

            <Flex className={classes.sendFileButton}>
              <FileButton
                multiple
                onChange={(uploadedFiles) => {
                  setError('');
                  if (!uploadedFiles) {
                    return;
                  }
                  const validFiles = uploadedFiles.filter(
                    (file) => !(file?.size > FIVE_MB),
                  );
                  const invalidFiles = uploadedFiles.filter(
                    (file) => file?.size > FIVE_MB,
                  );
                  if (invalidFiles?.length) {
                    const errorFiles = invalidFiles
                      .map((file) => file.name)
                      .join(', ');
                    setError(
                      `Розмір файлів: '${errorFiles}' має бути меньше 5мб`,
                    );
                  }
                  if (!validFiles.length) {
                    return;
                  }
                  const filesLimitReached =
                    (lastHomework?.filePaths?.length || 0) +
                      validFiles.length ===
                    MAX_HOMEWORK_FILES;
                  if (filesLimitReached) {
                    setError(
                      `Максимальна кількість файлів для одного комментаря: ${MAX_HOMEWORK_FILES}.`,
                    );
                    return;
                  }
                  setFiles(validFiles);
                }}
                disabled={
                  lastHomework?.status === HomeWorkStatus.Submitted
                    ? false
                    : !value
                }
              >
                {(props) => (
                  <UnstyledButton {...props} mr="24px">
                    <AttachmentIcon />
                  </UnstyledButton>
                )}
              </FileButton>

              <Button
                size="md"
                fz="md"
                display="block"
                disabled={
                  lastHomework?.status === HomeWorkStatus.Submitted
                    ? !files?.length && !value
                    : !value
                }
                onClick={handleUpdateHomework}
              >
                {lastHomework?.status === HomeWorkStatus.Submitted
                  ? 'Оновити'
                  : 'Відправити'}
              </Button>
            </Flex>
          </Flex>
          {lastHomework?.status === HomeWorkStatus.Submitted && (
            <Text fz="12px" pt="6px" c={bodyTextColorHex}>
              Якщо ментор не перевірив домашнє завдання, ви можете повністю
              змінити коментар або додати файли. <br />
              Максимальна кількість файлів для одного коментаря:{' '}
              {MAX_HOMEWORK_FILES}
            </Text>
          )}

          {!!files?.length && (
            <Flex wrap="wrap">
              {files.map((file) => (
                <Box p={6}>
                  <File name={file.name} />
                </Box>
              ))}
            </Flex>
          )}
          {error && (
            <Text mt="md" c="red">
              {error}
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
};

const File = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => Promise<void>;
}) => (
  <Flex
    mt="sm"
    display="flex"
    onClick={onClick}
    className={onClick && classes.file}
  >
    <AttachmentIcon />
    <Text ml="2px" c={bodyTextColorHex}>
      {name}
    </Text>
  </Flex>
);

const Avatar = ({ avatar }: { avatar?: string }) => (
  <Image src={avatar || EmptyUser} className={classes.avatar} />
);

export default HomeWork;
