import { Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import PageContainer from '../../../components/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { ROOT_PATH } from '../../routes';
import { getCourse } from '../../../api/courses';
import { bodyTextColorHex } from '../../../theme';
import CourseBlock from './components/CourseBlock';

const CourseStudy = () => {
  const { courseId, studyFlowId } = useParams();
  const navigate = useNavigate();

  const {
    data: course,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: ['course', courseId],
    queryFn: async () => courseId && getCourse(courseId, studyFlowId as string),
  });

  if (!course) {
    if (isFetched) {
      navigate(ROOT_PATH);
    }
    return null;
  }

  return (
    <PageContainer title="Матеріали">
      {course.courseBlocks?.length ? (
        course.courseBlocks.map((courseBlock) => (
          <CourseBlock
            key={courseBlock._id}
            courseBlock={courseBlock}
            courseId={course._id}
            refetchCourseBlocks={refetch}
          />
        ))
      ) : (
        <Title
          order={4}
          fz={24}
          fw="normal"
          c={bodyTextColorHex}
          mb={48}
          mt={70}
          ta="center"
        >
          Цей курс не має блоків
        </Title>
      )}
    </PageContainer>
  );
};

export default CourseStudy;
